import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We're all familiar with dialogs. From simple prompts for confirming an action to content-heavy windows —
dialogs are an integral part of modern web user interfaces.`}</p>
    <p>{`Unfortunately, we didn't have a native dialog element for a long time, leading to different
`}<a parentName="p" {...{
        "href": "https://accessuse.eu/en/modal-dialogs.html"
      }}>{`custom implementations with many accessibility issues`}</a>{`.
This changed when HTML 5.2 introduced the `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` element. With Safari finally adding
support in version 15.4, `}<a parentName="p" {...{
        "href": "https://caniuse.com/dialog"
      }}>{`all modern browsers`}</a>{` now support the element.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAXAQADAQAAAAAAAAAAAAAAAAABAgME/9oADAMBAAIQAxAAAAFpVfOm6SgH/8QAGRABAAIDAAAAAAAAAAAAAAAAAgEQAxES/9oACAEBAAEFAoEI5RxW7//EABcRAAMBAAAAAAAAAAAAAAAAAAIQETH/2gAIAQMBAT8BhXV//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABcQAQADAAAAAAAAAAAAAAAAABEAIDH/2gAIAQEABj8CWbX/xAAXEAEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEBAAE/ITiWsQHrXLbctt//2gAMAwEAAgADAAAAEB8f/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERMVH/2gAIAQMBAT8QSrzwp//EABYRAQEBAAAAAAAAAAAAAAAAABEQMf/aAAgBAgEBPxBOz//EABgQAQEBAQEAAAAAAAAAAAAAAAERACFB/9oACAEBAAE/EA8aUEwKXGEk5c9akfT3Ovcq7//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Two white speech bubbles on a pink background.",
          "title": "Two white speech bubbles on a pink background.",
          "src": "/static/ebce27ac9b0deac34067a84ad6da1b6f/e5166/pexels-miguel-a-padrinan-speech-bubbles.jpg",
          "srcSet": ["/static/ebce27ac9b0deac34067a84ad6da1b6f/f93b5/pexels-miguel-a-padrinan-speech-bubbles.jpg 300w", "/static/ebce27ac9b0deac34067a84ad6da1b6f/b4294/pexels-miguel-a-padrinan-speech-bubbles.jpg 600w", "/static/ebce27ac9b0deac34067a84ad6da1b6f/e5166/pexels-miguel-a-padrinan-speech-bubbles.jpg 1200w", "/static/ebce27ac9b0deac34067a84ad6da1b6f/b17f8/pexels-miguel-a-padrinan-speech-bubbles.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Miguel Á. Padriñán / pexels.com`}</em></p>
    <p>{`I'll show you how easy it is to create an accessible, modal dialog using the native element. Some minor accessibility
issues remain with some browsers and screen readers, which I'll cover at the end of the post.`}</p>
    <h2>{`What does the `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` element do?`}</h2>
    <p>{`The dialog element creates a popup box on your website that draws the user's attention.
The `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/multipage/interactive-elements.html#the-dialog-element"
      }}>{`HTML specification`}</a>{` states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The dialog element represents a part of an application that a user interacts with to perform a task, for example a dialog box, inspector, or window.`}</p>
    </blockquote>
    <p>{`A typical use case would be a modal dialog that obscures the rest of the page and asks the user for some input.
I've created a `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/dialog"
      }}>{`demo`}</a>{` using the React framework
(`}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/fancy-css-playground/tree/main/src/pages/DialogElement"
      }}>{`source code`}</a>{`):`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/dialog" title="Demo Native Dialog Element"></iframe>
    <h2>{`Basic Setup and Interaction with the Dialog`}</h2>
    <p>{`The basic `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX code`}</a>{` of my modal dialog demo looks like this:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<dialog
    aria-labelledby='dialog-personal-info-heading'
    ref={formDialogRef}
    onClick={onFormDialogContainerClick}
>
    <h3 id="dialog-personal-info-heading">
        Personal Information
    </h3>
    <p>...</p>
    <form
        method="dialog"
        onClick={event => event.stopPropagation()}
    >
        // form elements
    </form>
</dialog>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`By default, the browser won't display the dialog until you pass in an `}<InlineCode mdxType="InlineCode">{`open`}</InlineCode>{` property to make it
visible. It is recommended to use the `}<InlineCode mdxType="InlineCode">{`.show()`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`.showModal()`}</InlineCode>{` methods
to render dialogs, rather than the open attribute.`}</p>
    <p>{`In my case, I want to show a modal dialog that obscures the rest of the page. In my functional React component, I retrieve
a reference to the HTML element with the `}<InlineCode mdxType="InlineCode">{`useRef`}</InlineCode>{` hook and open the dialog on a button click:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`const formDialogRef = useRef<HTMLDialogElement>(null);

const onOpenFormDialogClick = () => {
    formDialogRef.current?.showModal();
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`To close the dialog, use the `}<InlineCode mdxType="InlineCode">{`.close()`}</InlineCode>{` method. As my demo shows,
a `}<InlineCode mdxType="InlineCode">{`<`}{`form`}{`>`}</InlineCode>{` element can also close the dialog using the
attribute `}<InlineCode mdxType="InlineCode">{`method="dialog"`}</InlineCode>{`. When the form is submitted, the dialog closes with
its `}<InlineCode mdxType="InlineCode">{`returnValue`}</InlineCode>{` property set to the value of the button that was used to submit the form:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<form method="dialog">
    <div className={styles.formField}>
        <label htmlFor="favMovie">Favorite movie:</label>
        <input id="favMovie" type="text" />
    </div>
    <button
        type="submit"
        value={DIALOG_CONFIRM_VALUE}
    >
        Confirm
    </button>
</form>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can add an event listener for the dialog's `}<InlineCode mdxType="InlineCode">{`close`}</InlineCode>{` event and trigger an action
depending on the value of the `}<InlineCode mdxType="InlineCode">{`returnValue`}</InlineCode>{` property.`}</p>
    <h2>{`Styling the Dialog and its Backdrop`}</h2>
    <p>{`The modal dialog is rendered in the center of the page on top of the other content. Browsers apply a default style
to the `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element, usually a thick black border. You can easily customize the dialog's
appearance with CSS. For example, add a drop shadow and rounded corners like this:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`dialog {
    border: 0.125rem solid var(--border-color);
    border-radius: 4px;
    box-shadow:
        0 11px 15px -7px #0003,
        0 24px 38px 3px #00000024,
        0 9px 46px 8px #0000001f;
    font-size: 1rem;
    max-width: min(18rem, 90vw);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`A really awesome feature of the dialog element is the `}<InlineCode mdxType="InlineCode">{`::backdrop`}</InlineCode>{` CSS pseudo-element.
It allows you to style behind a modal dialog to, e.g., dim and blur the unreachable content of the page:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`dialog::backdrop {
    background: rgba(36, 32, 20, 0.5);
    backdrop-filter: blur(0.25rem);
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Keyboard and Mouse Control`}</h2>
    <p>{`When the modal dialog is opened, the browser moves focus to the first interactive element inside of the dialog.
This works well for many use cases, like my form dialog. But sometimes you would prefer to set the focus on
the whole dialog or a text element at the top. Learn more about the issue in
this `}<a parentName="p" {...{
        "href": "https://github.com/whatwg/html/wiki/dialog--initial-focus,-a-proposal"
      }}>{`proposal for initial focus placement`}</a>{`.`}</p>
    <p>{`While the modal dialog is active, the content obscured by the dialog is inaccessible to all users. This means
that keyboard users can't leave the dialog with the `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{` key, and a screen reader's virtual
cursor (arrow keys or swiping) is not allowed to leave the modal dialog as long as it remains open.`}</p>
    <p>{`Users can close the modal dialog with the `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{` key. On close, focus returns to the control that
initially activated the dialog. This allows keyboard and screen reader users to continue browsing from where they left off.`}</p>
    <p>{`Unfortunately, the `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element doesn't close automatically when the user clicks
outside of it. If we want to implement this behavior, we can get the coordinates of the click and compare them
to the dialog's rectangle (thanks for the idea, `}<a parentName="p" {...{
        "href": "https://blog.logrocket.com/using-the-dialog-element/"
      }}>{`Amit Merchant`}</a>{`):`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`const isClickOutsideOfDialog = (dialogEl: HTMLDialogElement, event: React.MouseEvent): boolean => {
    const rect = dialogEl.getBoundingClientRect();
    return (event.clientY < rect.top
         || event.clientY > rect.bottom
         || event.clientX < rect.left
         || event.clientX > rect.right);
}

const onFormDialogContainerClick = (event: React.MouseEvent) => {
    const formDialogEl = formDialogRef.current;
    if (formDialogEl &&
        isClickOutsideOfDialog(formDialogEl, event)
    ){
        formDialogEl.close(DIALOG_CANCEL_VALUE);
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This works fine, except for some use cases. For example, opening a native select inside the dialog would count as
a click outside of the dialog and close it. Therefore, I apply a click event listener to the `}<InlineCode mdxType="InlineCode">{`form`}</InlineCode>{`
element and use the `}<InlineCode mdxType="InlineCode">{`.stopPropagation()`}</InlineCode>{` method.`}</p>
    <h2>{`Accessibility Issues with some Screen Readers`}</h2>
    <p>{`The native `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element works well with most screen readers and browsers. But still,
some issues remain as the accessibility audits of my `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/dialog"
      }}>{`demo`}</a>{`
on different platforms have shown:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Windows 10, Google Chrome 103.0.5060.114, NVDA 2022.1`}</strong>{`: When the dialog is opened and receives focus, the screen reader
announces the dialog role, the heading (thanks to `}<InlineCode mdxType="InlineCode">{`aria-labelledby`}</InlineCode>{`), the first paragraph and the
focused select element. Focus order and the screen reader's virtual cursor are limited to the dialog's content.`}</li>
      <li parentName="ul"><strong parentName="li">{`Windows 10, Firefox 102.0.1, NVDA 2022.1`}</strong>{`: Identical to Google Chrome, except that the button that opened the dialog
is part of the focus order. Probably a Firefox bug that will be fixed in the future.`}</li>
      <li parentName="ul"><strong parentName="li">{`Samsung Galaxy S20, Android 12, Google Chrome 103.0.5060.71, TalkBack`}</strong>{`: The screen reader only announces the focused
select element. The virtual cursor (e.g. swipe right) is limited to the dialog's content.`}</li>
      <li parentName="ul"><strong parentName="li">{`Samsung Galaxy S20, Android 12, Firefox 102.2.1, TalkBack`}</strong>{`: The screen reader only announces the focused
select element. The virtual cursor is not limited to the dialog's content. This is probably due to Firefox still not
supporting the `}<a parentName="li" {...{
          "href": "https://caniuse.com/mdn-api_element_ariamodal"
        }}>{`aria-modal`}</a>{` property.`}</li>
      <li parentName="ul"><strong parentName="li">{`iPhone 8, iOS 15.5, Safari, VoiceOver`}</strong>{`: The screen reader only announces the focused select element. The virtual cursor
can be moved outside the dialog to the elements in the header, but not to the elements in the main content section.`}</li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` element is easy to use and accessible by default, apart from some minor issues.
Right now, a robust custom dialog like `}<a parentName="p" {...{
        "href": "https://github.com/KittyGiraudel/a11y-dialog"
      }}>{`a11y-dialog`}</a>{` might still be the
better option for some use cases. But I'm very optimistic about the native element's future.`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog"
        }}>{`The Dialog element (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/whatwg/html/wiki/dialog--initial-focus,-a-proposal"
        }}>{`Dialog initial focus, a proposal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nngroup.com/articles/modal-nonmodal-dialog/"
        }}>{`Modal & Nonmodal Dialogs: When (& When Not) to Use Them`}</a></li>
    </ul>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update on 03/07/2023</PostUpdateTitle>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/whatwg/html/commit/a9f103c9f7bd09ef712990194638c75db1f50e3c"
      }}>{`HTML specification has received an important update`}</a>{`
regarding initial focus management. It will be possible to make the `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element itself
get focus if it has the `}<InlineCode mdxType="InlineCode">{`autofocus`}</InlineCode>{` attribute set.`}</p>
    <p>{`Sure, it'll take some time until browser vendors implement these changes. But there's no reason to wait any longer! I agree
with `}<a parentName="p" {...{
        "href": "https://www.scottohara.me/blog/2023/01/26/use-the-dialog-element.html"
      }}>{`Scott O'Hara`}</a>{`: `}<ItalicText mdxType="ItalicText">{`“Instead of
waiting for perfect, I personally think it’s time to move away from using custom dialogs, and to use the dialog element
instead.”`}</ItalicText></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      